<template>
  <app-timeline-item icon="ShoppingCartIcon">
    <div class="mb-1 mb-sm-0">
      <h4>{{ $t('src.layouts.delivery-summary.producttimelineitem.product') }}</h4>
      <b-container>
        <b-row class="justify-content-md-left mt-2">
          <b-col
            col
            lg="3"
          >
            <h6>{{ category }}</h6>
          </b-col>
          <b-col
            cols="12"
            md="auto"
          >
            <b-table
              thead-class="table-header-hidden"
              bordered
              :items="items"
            />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </app-timeline-item>
</template>

<script>
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'ProductTimelineItem',
  components: {
    AppTimelineItem,
  },
  props: {
    category: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
  },
}
</script>
