<template>
  <div class="mb-1 mb-sm-0">
    <b-container>
      <div class="d-flex justify-content-between mt-2">
        <div class="text-center mb-2">
          <h5 class="mb-1">
            {{ $t('src.layouts.delivery-summary.timeline.pickUP') }}
          </h5>
          <b-link
            v-b-modal.pick-up-modal
          >
            <b-img
              thumbnail
              fluid
              :src="pickupPhoto"
              v-bind="proofOfDelivery"
              rounded="0"
              alt="pick up photo"
              class="d-inline-block"
            />
          </b-link>
        </div>
        <div class="text-center mb-2">
          <h5 class="mb-1">
            {{ $t('src.layouts.delivery-summary.timeline.dropOP') }}
          </h5>
          <b-link
            v-b-modal.drop-off-modal
          >
            <b-img
              thumbnail
              fluid
              :src="dropOffPhoto"
              v-bind="proofOfDelivery"
              rounded="0"
              alt="drop off photo"
              class="d-inline-block"
            />
          </b-link>
        </div>
        <div class="text-center mb-2">
          <h5 class="mb-1">
            {{ $t('src.layouts.delivery-summary.timeline.goodRN') }}
          </h5>
          <b-link
            v-b-modal.grn-modal
          >
            <b-img
              thumbnail
              fluid
              :src="goodReturnPhoto"
              v-bind="proofOfDelivery"
              rounded="0"
              alt="good return notes"
              class="d-inline-block"
            />
          </b-link>
        </div>
      </div>
    </b-container>
    <b-modal
      id="pick-up-modal"
      :title="$t('src.layouts.delivery-summary.timeline.pickU')"
      ok-only
      :ok-title="$t('src.layouts.delivery-summary.timeline.close')"
      size="lg"
    >
      <b-card-text>
        <div class="container d-flex justify-content-center align-items-center">
          <b-img
            fluid
            :src="pickupPhoto"
            rounded="0"
            alt="pick up photo"
            class="d-inline-block"
          />
        </div>
      </b-card-text>
    </b-modal>
    <b-modal
      id="drop-off-modal"
      :title="$t('src.layouts.delivery-summary.timeline.dropO')"
      ok-only
      :ok-title="$t('src.layouts.delivery-summary.timeline.close')"
      size="lg"
    >
      <b-card-text>
        <div class="container d-flex justify-content-center align-items-center">
          <b-img
            fluid
            :src="dropOffPhoto"
            rounded="0"
            alt="dropoff photo"
            class="d-inline-block"
          />
        </div>
      </b-card-text>
    </b-modal>
    <b-modal
      id="grn-modal"
      :title="$t('src.layouts.delivery-summary.timeline.goodRN')"
      ok-only
      :ok-title="$t('src.layouts.delivery-summary.timeline.close')"
      size="lg"
    >
      <b-card-text>
        <div class="container d-flex justify-content-center align-items-center">
          <b-img
            fluid
            :src="goodReturnPhoto"
            rounded="0"
            alt="good return photo"
            class="d-inline-block"
          />
        </div>
      </b-card-text>
    </b-modal>
  </div>

</template>

<script>

export default {
  name: 'ProofOfDelivery',
  props: {
    pickupPhoto: {
      type: String,
      default: null,
    },
    dropOffPhoto: {
      type: String,
      default: null,
    },
    goodReturnPhoto: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      proofOfDelivery: [{
        width: 200,
        height: 200,
      }],
    }
  },
}
</script>
