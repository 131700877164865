<template>
  <app-timeline-item icon="CalendarIcon">
    <div class="mb-1 mb-sm-0">
      <h4>{{ $t('src.layouts.delivery-summary.datetimelineitem.date') }}</h4>
      <b-container class="d-flex align-items-center">
        <div class="flex-grow-1">
          <b-row class="justify-content-md-left align-items-center mt-2">
            <b-col
              col
              lg="3"
            >
              <h6>{{ $t('src.layouts.delivery-summary.datetimelineitem.orderD') }}</h6>
            </b-col>
            <b-col
              cols="12"
              md="auto"
            >
              <h6>{{ $helpers.formatDateTime(orderData.date_added) }}</h6>
            </b-col>
          </b-row>
          <b-row class="justify-content-md-left align-items-center mt-2">
            <b-col
              col
              lg="3"
            >
              <h6>{{ $t('src.layouts.delivery-summary.datetimelineitem.deliveryD') }}</h6>
              <p>&nbsp;</p>
            </b-col>
            <b-col
              cols="12"
              md="auto"
            >
              <h6 v-if="!editMode">
                {{ $helpers.formatDateTime(deliveryDate) }}
              </h6>
              <flat-pickr
                v-else
                v-model="date"
                :config="{time_24hr: true, enableTime: true, dateFormat: 'Y-m-d H:i', minDate: defaultDeliveryDateTime}"
                :placeholder="$t('src.layouts.delivery-summary.datetimelineitem.yyyymmddH')"
                class="form-control"
              />
              <p><small>{{ $t('src.views.pages.inside.createdelivery.time_of_delivery_info') }}</small></p>
            </b-col>
          </b-row>
        </div>
        <div>
          <a
            v-if="!editMode"
            href="#"
            @click.prevent="editFields"
          >{{ $t('src.layouts.delivery-summary.datetimelineitem.edit') }}</a>
          <a
            v-else-if="editMode"
            href="#"
            @click.prevent="updateInfo"
          >{{ $t('src.layouts.delivery-summary.datetimelineitem.save') }}</a>
        </div>
      </b-container>
    </div>
  </app-timeline-item>
</template>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<script>
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'DateTimelineItem',
  components: {
    AppTimelineItem,
    flatPickr,
  },
  props: {
    orderData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      editMode: false,
      date: null,
      deliveryDate: null,
    }
  },
  computed: {
    defaultDeliveryDateTime() {
      const today = new Date()
      today.setHours(today.getHours() + 4)
      return today
    },
  },
  created() {
    this.date = this.defaultDeliveryDateTime
    this.deliveryDate = this.orderData.delivery_date
  },
  methods: {
    editFields() {
      this.editMode = !this.editMode
    },
    updateInfo() {
      this.editMode = !this.editMode
      this.orderData.delivery_date = this.date
      console.log(this.orderData.delivery_date)
      this.deliveryDate = this.date
      this.$emit('updateOrder')
    },
  },
}
</script>
