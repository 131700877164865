<template>
  <app-timeline-item icon="FileTextIcon">
    <div class="mb-1 mb-sm-0">
      <h4>{{ title }}</h4>
      <b-container>
        <b-row class="justify-content-md-left align-items-center mt-2">
          <b-col
            col
            lg="2"
          >
            <b-spinner
              v-if="downloadedFile === typeOfFile"
              variant="primary"
              :label="$t('src.layouts.delivery-summary.timeline.dowloading')"
            />
            <a
              v-else
              href="#"
              @click.prevent="$emit('downloadFile', typeOfFile)"
            >{{ btnText }}</a>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </app-timeline-item>
</template>

<script>
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'DownloadableTemplate',
  components: {
    AppTimelineItem,
  },
  props: {
    downloadedFile: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    btnText: {
      type: String,
      default: '',
    },
    typeOfFile: {
      type: String,
      default: '',
    },
  },
}
</script>
