<template>
  <app-timeline-item icon="BookIcon">
    <div class="mb-1 mb-sm-0">
      <h4>{{ $t('src.layouts.delivery-summary.timeline.invoice') }}</h4>
      <b-container>
        <b-row class="justify-content-md-left align-items-center mt-2">
          <b-col
            col
            lg="2"
          >
            <a
              v-if="order.invoice"
              :href="order.invoice.receipt"
              target="_blank"
            >{{ $t('src.layouts.delivery-summary.timeline.downloadH') }}</a>

            <span
              v-if="!order.invoice"
              class="text-primary cursor-pointer"
              @click.prevent="handleDownloadInvoice()"
            >
              <b-spinner
                v-if="isInvoiceDownloading"
                class="mr-50"
              />
              <span v-else>{{ $t('src.layouts.delivery-summary.timeline.downloadH') }}</span>
            </span>

          </b-col>
        </b-row>
      </b-container>
    </div>
  </app-timeline-item>
</template>

<script>
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import axios from '@axios'

export default {
  name: 'DownloadInvoice',
  components: {
    AppTimelineItem,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isInvoiceDownloading: false,
    }
  },
  methods: {
    async handleDownloadInvoice() {
      this.isInvoiceDownloading = true
      const response = await axios.get(`/download-invoice/${this.order.payment.id}/${this.order.payment_method}`, { responseType: 'blob' })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute('download', `invoice-${this.order.payment.transaction_id ?? this.order.payment.reference_number}.pdf`)
      document.body.appendChild(fileLink)
      fileLink.click()
      this.isInvoiceDownloading = false
    },
  },
}
</script>
