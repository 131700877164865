<template>
  <app-timeline-item icon="ClipboardIcon">
    <div class="mb-1 mb-sm-0">
      <h4>{{ $t('src.layouts.delivery-summary.deliveryoverview.deliveryO') }}</h4>
      <b-container>
        <b-row class="justify-content-md-left align-items-center mt-2">
          <b-col
            col
            lg="3"
          >
            <h6>{{ $t('src.layouts.delivery-summary.deliveryoverview.orderI') }}</h6>
          </b-col>
          <b-col
            cols="12"
            md="auto"
          >
            <h2>{{ deliveryId }}</h2>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-left  align-items-center  mt-2">
          <b-col
            col
            lg="3"
          >
            <h6>{{ $t('src.layouts.delivery-summary.deliveryoverview.status') }}</h6>
          </b-col>
          <b-col
            cols="12"
            md="auto"
          >
            <h4>
              <b-badge :variant="status.color_name">
                {{ status.text }}
              </b-badge>
            </h4>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-left mt-2">
          <b-col
            col
            lg="3"
          >
            <h6>{{ $t('src.layouts.delivery-summary.deliveryoverview.userI') }}</h6>
          </b-col>
          <b-col
            cols="12"
            md="auto"
          >
            <h6>{{ userId }}</h6>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-left mt-2">
          <b-col
            col
            lg="3"
          >
            <h6>{{ $t('src.layouts.delivery-summary.deliveryoverview.totalS') }}</h6>
          </b-col>
          <b-col
            cols="12"
            md="auto"
          >
            <h6>{{ $helpers.formatTotal(totalSpent) }}</h6>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </app-timeline-item>
</template>

<script>
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  name: 'DeliveryOverview',
  components: {
    AppTimelineItem,
  },
  props: {
    deliveryId: {
      type: String,
      default: null,
    },
    status: {
      type: Object,
      default: () => {},
    },
    userId: {
      type: Number,
      default: null,
    },
    totalSpent: {
      type: [Number, String],
      default: 0,
    },
  },

}
</script>
