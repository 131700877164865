<template>
  <app-timeline-item icon="InfoIcon">
    <div class="mb-1 mb-sm-0">
      <h4>{{ $t('src.layouts.delivery-summary.yourinformation.yourI') }}</h4>
      <b-container class="d-flex align-items-center">
        <div class="flex-grow-1">
          <b-row class="justify-content-md-left mt-2 align-items-center">
            <b-col
              col
              lg="3"
            >
              <h6>{{ $t('src.layouts.delivery-summary.yourinformation.poN') }}</h6>
            </b-col>
            <b-col
              cols="12"
              md="auto"
            >
              <h6 v-if="!editMode">
                {{ orderData.po_number }}
              </h6>
              <b-form-input
                v-else
                id="po-number"
                v-model="orderData.po_number"
                :placeholder="$t('src.layouts.delivery-summary.yourinformation.poN')"
                style="background-color: inherit"
              />
            </b-col>
          </b-row>
          <b-row class="justify-content-md-left mt-2 align-items-center">
            <b-col
              col
              lg="3"
            >
              <h6>{{ $t('src.layouts.delivery-summary.yourinformation.poED') }}</h6>
            </b-col>
            <b-col
              cols="12"
              md="auto"
            >
              <h6 v-if="!editMode">
                {{ $helpers.formatDate(poExpiryDate) }}
              </h6>
              <flat-pickr
                v-else
                v-model="date"
                :placeholder="$t('src.layouts.delivery-summary.yourinformation.yyyymmdd')"
                :config="{dateFormat: 'Y-m-d', minDate: defaultDeliveryDateTime}"
                class="form-control"
              />
            </b-col>
          </b-row>
          <b-row class="justify-content-md-left mt-2 align-items-center">
            <b-col
              col
              lg="3"
            >
              <h6>{{ $t('src.layouts.delivery-summary.yourinformation.invoiceN') }}</h6>
            </b-col>
            <b-col
              cols="12"
              md="auto"
            >
              <h6 v-if="!editMode">
                {{ orderData.invoice_number }}
              </h6>
              <b-form-input
                v-else
                id="invoice-number"
                v-model="orderData.invoice_number"
                :placeholder="$t('src.layouts.delivery-summary.yourinformation.invoiceN')"
                style="background-color: inherit"
              />
            </b-col>
          </b-row>
        </div>
        <div>
          <a
            v-if="!editMode"
            href="#"
            @click.prevent="editOrder"
          >{{ $t('src.layouts.delivery-summary.yourinformation.edit') }}</a>
          <a
            v-else-if="editMode"
            href="#"
            @click.prevent="updateInfo"
          >{{ $t('src.layouts.delivery-summary.yourinformation.save') }}</a>
        </div>
      </b-container>
    </div>
  </app-timeline-item>
</template>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<script>
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  name: 'YourInformation',
  components: {
    AppTimelineItem,
    flatPickr,
  },
  props: {
    orderData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      editMode: false,
      date: null,
      poExpiryDate: null,
    }
  },
  computed: {
    defaultDeliveryDateTime() {
      const today = new Date()
      today.setHours(today.getHours() + 4)
      return today
    },
  },
  created() {
    this.date = this.defaultDeliveryDateTime
    this.poExpiryDate = this.orderData.po_expiry_date
  },
  methods: {
    editOrder() {
      this.editMode = !this.editMode
    },
    updateInfo() {
      this.editMode = !this.editMode
      this.orderData.po_expiry_date = this.date
      this.poExpiryDate = this.date
      this.$emit('updateOrder')
    },
  },
}
</script>
