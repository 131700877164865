<template>
  <b-overlay :show="isLoading" rounded="sm">
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <h1 class="font-weight-bolder text-primary">
          {{ $t('src.views.pages.inside.ordertracking.orderT') }}
        </h1>
      </div>
      <div class="bg-white d-flex align-items-center">
        <div class="bg-primary py-1 px-3 text-center">
          <p class="font-weight-bolder m-0 p-0 h5 text-white">
            {{ $t('src.views.pages.inside.ordertracking.orderI') }}
          </p>
        </div>
        <div class="py-1 px-3">
          <p class="font-weight-bolder m-0 p-0 h5 text-success">
            {{ order.reference_id }}
          </p>
        </div>
      </div>
    </div>
    <div class="card box-shadow-none mt-2 d-lg-block d-none">
      <div class="card-body">
        <div class="my-2 text-center" style="position: relative">
          <div style="position: relative; padding-left: 100px">
            <div class="bg-primary line" />
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div class="track d-flex flex-column align-items-center">
              <div
                :class="[
                  'timeline-item-icon',
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  'rounded-circle',
                  'border p-1 mb-1 icon',
                  order.status.value >= 1 && order.status.value != 10
                    ? 'active-tracking border-primary'
                    : 'bg-white'
                ]"
              >
                <feather-icon
                  color="#ea5455"
                  size="25"
                  icon="ShoppingCartIcon"
                />
              </div>
              <h6 class="mb-0">
                {{ $t('src.views.pages.inside.ordertracking.orderP') }}
              </h6>
            </div>
            <div class="track d-flex flex-column align-items-center">
              <div
                :class="[
                  'timeline-item-icon',
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  'rounded-circle',
                  'border p-1 mb-1 icon',
                  order.status.value >= 2 && order.status.value != 10
                    ? 'active-tracking border-primary'
                    : 'bg-white'
                ]"
              >
                <feather-icon color="#ea5455" size="25" icon="LoaderIcon" />
              </div>
              <h6 class="mb-0">
                {{ $t('src.views.pages.inside.ordertracking.processing') }}
              </h6>
            </div>
            <div class="track d-flex flex-column align-items-center">
              <div
                :class="[
                  'timeline-item-icon',
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  'rounded-circle',
                  'border p-1 mb-1 icon',
                  order.status.value >= 6 && order.status.value != 10
                    ? 'active-tracking border-primary'
                    : 'bg-white'
                ]"
              >
                <feather-icon color="#ea5455" size="25" icon="InboxIcon" />
              </div>
              <h6 class="mb-0">
                {{ $t('src.views.pages.inside.ordertracking.pickedU') }}
              </h6>
            </div>
            <div class="track d-flex flex-column align-items-center">
              <div
                :class="[
                  'timeline-item-icon',
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  'rounded-circle',
                  'border p-1 mb-1 icon',
                  order.status.value >= 7 && order.status.value != 10
                    ? 'active-tracking border-primary'
                    : 'bg-white'
                ]"
              >
                <feather-icon color="#ea5455" size="25" icon="TruckIcon" />
              </div>
              <h6 class="mb-0">
                {{ $t('src.views.pages.inside.ordertracking.outFD') }}
              </h6>
            </div>
            <div class="track d-flex flex-column align-items-center">
              <div
                :class="[
                  'timeline-item-icon',
                  'd-flex',
                  'align-items-center',
                  'justify-content-center',
                  'rounded-circle',
                  'border p-1 mb-1 icon',
                  order.status.value >= 8 && order.status.value != 10
                    ? 'active-tracking border-primary'
                    : 'bg-white'
                ]"
              >
                <feather-icon
                  color="#ea5455"
                  size="25"
                  icon="CheckCircleIcon"
                />
              </div>
              <h6 class="mb-0">
                {{ $t('src.views.pages.inside.ordertracking.delivered') }}
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-row v-if="order.order_type !== 'CHARTERED'" class="mt-2">
      <b-col>
        <div class="card box-shadow-none">
          <div class="card-body">
            <h4 class="card-title font-weight-bolder mb-0">
              {{ $t('src.views.pages.inside.ordertracking.orderS') }}
            </h4>
            <div class="p-1">
              <h5 class="font-weight-bolder">
                {{ $t('src.views.pages.inside.ordertracking.orderI') }}
                {{ order.reference_id }}
              </h5>
              <h5 class="font-weight-bolder">
                {{ $t('src.views.pages.inside.ordertracking.status')
                }}<b-badge :variant="order.status.color_name">
                  {{ getStatusText(order.status) }}
                </b-badge>
                <b-badge v-if="order.received_at" variant="green" class="ml-1">
                  {{ $t('order_received') }}
                </b-badge>
              </h5>
              <h5 class="font-weight-bolder">
                {{ $t('src.views.pages.inside.ordertracking.grandTotal') }} :
                {{ $helpers.formatTotal(order.price) }}
              </h5>
              <h5 class="font-weight-bolder">
                {{
                  $t('src.views.pages.inside.ordertracking.orderPD', {
                    '1': $helpers.formatDateTime(order.date_added)
                  })
                }}
              </h5>
              <h5 class="font-weight-bolder">
                {{
                  $t('src.views.pages.inside.ordertracking.deliveryD', {
                    '1': $helpers.formatDateTime(order.delivery_date)
                  })
                }}
              </h5>
            </div>
          </div>
        </div>
        <div class="card box-shadow-none">
          <div class="card-body">
            <h4 class="card-title font-weight-bolder mb-0">
              {{ $t('src.views.pages.inside.ordertracking.productD') }}
            </h4>
            <div class="p-1">
              <h5 class="font-weight-bolder">
                {{
                  $t('src.views.pages.inside.ordertracking.quantity', {
                    '1': order.quantity
                  })
                }}
              </h5>
              <h5 class="font-weight-bolder">
                {{
                  $t('src.views.pages.inside.ordertracking.remarks', {
                    '1': order.remarks
                  })
                }}
              </h5>
              <h5 class="font-weight-bolder">
                {{
                  $t('src.views.pages.inside.ordertracking.documents', {
                    '1': ''
                  })
                }}
              </h5>
              <div class="d-flex mb-1">
                <b-button
                  variant="danger"
                  size="sm"
                  type="button"
                  @click.prevent="reprint('do')"
                  >{{
                    $t('src.views.pages.inside.ordertracking.reprintD')
                  }}</b-button
                >
                <b-button
                  variant="danger"
                  size="sm"
                  class="mx-1"
                  type="button"
                  @click.prevent="reprint('grn')"
                  >{{
                    $t('src.views.pages.inside.ordertracking.reprintG')
                  }}</b-button
                >
                <b-button
                  variant="danger"
                  size="sm"
                  class="mr-1"
                  type="button"
                  @click.prevent="reprint('cover')"
                  >{{
                    $t('src.views.pages.inside.ordertracking.reprintCN')
                  }}</b-button
                >
                <b-button
                  v-if="order.invoice"
                  size="sm"
                  variant="primary"
                  :href="order.invoice ? order.invoice.receipt : '#'"
                  target="_blank"
                  >{{ $t('download_receipt') }}</b-button
                >

                <b-button
                  v-if="!order.invoice"
                  size="sm"
                  variant="primary"
                  :disabled="!order.payment || downloading"
                  @click.prevent="handleDownloadInvoice()"
                >
                  <b-spinner v-if="downloading" class="mr-50 p-0" small />
                  <span>{{ $t('download_receipt') }}</span>
                </b-button>
              </div>
              <h5 class="font-weight-bolder">
                {{
                  $t('src.views.pages.inside.ordertracking.barcode', {
                    '1': order.barcode
                  })
                }}
              </h5>
              <b-img
                :src="`${$barcodeUrl}${order.id}`"
                v-bind="barcode"
                rounded="0"
                alt="Barcode"
                class="d-inline-block"
              />
            </div>
          </div>
        </div>
        <div class="card box-shadow-none">
          <div class="card-body">
            <h4 class="card-title font-weight-bolder mb-0">
              {{ $t('src.views.pages.inside.ordertracking.origin') }}
            </h4>
            <div class="p-1">
              <h5 class="font-weight-bolder">
                {{
                  $t('src.views.pages.inside.ordertracking.sendersN', {
                    '1': order.user.name
                  })
                }}
              </h5>
              <h5 class="font-weight-bolder">
                {{
                  $t('src.views.pages.inside.ordertracking.sendersA', {
                    '1': order.sender.complete
                  })
                }}
              </h5>
              <h5 class="font-weight-bolder">
                {{
                  $t('src.views.pages.inside.ordertracking.personIC', {
                    '1': order.sender.name
                  })
                }}
              </h5>
              <h5 class="font-weight-bolder">
                {{
                  $t('src.views.pages.inside.ordertracking.phoneN', {
                    '1': order.sender.phone
                  })
                }}
              </h5>
            </div>
          </div>
        </div>
        <div class="card box-shadow-none">
          <div class="card-body">
            <h4 class="card-title font-weight-bolder mb-0">
              {{ $t('src.views.pages.inside.ordertracking.destination') }}
            </h4>
            <div class="p-1">
              <h5 class="font-weight-bolder">
                {{
                  $t('src.views.pages.inside.ordertracking.recipientsN', {
                    '1': order.recipient.name
                  })
                }}
              </h5>
              <h5 class="font-weight-bolder">
                {{
                  $t('src.views.pages.inside.ordertracking.recipientsA', {
                    '1': order.recipient.complete
                  })
                }}
              </h5>
              <h5 class="font-weight-bolder">
                {{
                  $t('src.views.pages.inside.ordertracking.personIC', {
                    '1': order.recipient.name
                  })
                }}
              </h5>
              <h5 class="font-weight-bolder">
                {{
                  $t('src.views.pages.inside.ordertracking.phoneN', {
                    '1': order.recipient.phone
                  })
                }}
              </h5>
            </div>
          </div>
        </div>
        <div class="card box-shadow-none">
          <div class="card-body">
            <h4 class="card-title font-weight-bolder mb-0">
              {{ $t('src.views.pages.inside.ordertracking.smarttruck') }}
            </h4>
            <div class="p-1">
              <h5 class="font-weight-bolder">
                {{
                  $t('src.views.pages.inside.ordertracking.typeOV', {
                    '1': order.vehicle ? order.vehicle.type : ''
                  })
                }}
              </h5>
              <h5 class="font-weight-bolder">
                {{
                  $t('src.views.pages.inside.ordertracking.driversN', {
                    '1': order.driver
                      ? $helpers.formatTextToCapitalized(order.driver.full_name)
                      : ''
                  })
                }}
              </h5>
              <h5 class="font-weight-bolder">
                {{
                  $t('src.views.pages.inside.ordertracking.companysN', {
                    '1': order.driver
                      ? $helpers.formatTextToCapitalized(
                          order.driver.company.name
                        )
                      : ''
                  })
                }}
              </h5>
            </div>
          </div>
        </div>
      </b-col>
      <b-col>
        <div class="card box-shadow-none">
          <div class="card-body">
            <h4 class="card-title font-weight-bolder">
              {{ $t('src.views.pages.inside.ordertracking.trackingS') }}
            </h4>
            <app-timeline>
              <app-timeline-item
                v-for="(summary, index) in order.logs"
                :key="index"
                :title="summary.title"
                :subtitle="summary.description"
                :time="summary.date_added"
                variant="warning"
              />
            </app-timeline>
          </div>
        </div>
      </b-col>
    </b-row>
    <div v-else>
      <div class="card box-shadow-none">
        <div class="card-body">
          <h4 class="card-title font-weight-bolder">TRACKING SUMMARY</h4>
          <app-timeline>
            <app-timeline-item
              v-for="(summary, index) in order.logs"
              :key="index"
              :title="summary.title"
              :subtitle="summary.description"
              :time="summary.date_added"
              variant="warning"
            />
          </app-timeline>
        </div>
      </div>
      <OrderDetails />
    </div>
    <b-row>
      <b-col>
        <div class="card box-shadow-none">
          <div class="card-body">
            <h4 class="card-title font-weight-bolder mb-0">
              {{ $t('src.views.pages.inside.ordertracking.proofOD') }}
            </h4>
            <ProofOfDelivery
              :drop-off-photo="order.delivered_photo"
              :pickup-photo="order.pickup_photo"
              :good-return-photo="order.good_return_photo"
            />
          </div>
        </div>
      </b-col>
    </b-row>
    <b-container class="text-center">
      <b-button
        type="button"
        variant="primary"
        class="mt-2"
        to="/track-order"
        >{{ $t('src.views.pages.inside.ordertracking.back') }}</b-button
      >
    </b-container>
    <template #overlay>
      <div class="text-center">
        <b-spinner
          style="width: 3rem; height: 3rem"
          label="Large Spinner"
          variant="primary"
        ></b-spinner>
        <h3 class="mt-3">Loading... Please wait.</h3>
      </div>
    </template>
  </b-overlay>
</template>
<style>
.line {
  height: 5px;
  position: absolute;
  top: 33px;
  width: 85%;
}
.track {
  z-index: 1;
  width: 15%;
}
.icon {
  width: 70px;
  height: 70px;
}
.active-tracking {
  background-color: #ea9fad;
}
</style>
<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import axios from '@axios'
import { ProofOfDelivery } from '@/components/DeliverySummary'
import { mapGetters } from 'vuex'
import { OrderDetails } from '@/components/CharteredTruck'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    ProofOfDelivery,
    OrderDetails
  },
  data() {
    return {
      barcode: [
        {
          width: 100,
          height: 50
        }
      ],
      proofOfDelivery: [
        {
          width: 200,
          height: 200
        }
      ],
      downloading: false,

      order: {},
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      // order: 'orders/order',
      // isLoading: 'orders/isLoading'
    })
  },
  created() {
    // if (typeof this.$route.params.id !== 'undefined') {
    //   this.$store.dispatch('orders/fetchOrder', {
    //     orderId: this.$route.params.id
    //   })
    // }
    this.getOrder()
  },
  methods: {
    async getOrder() {
      this.$http.get(`orders/${this.$route.params.id}`).then((response) => {
        if (response) {
          this.order = response.data.data
        }
        this.isLoading = false
      })
    },
    async reprint(type) {
      let fileName = ''
      if (type === 'do') {
        fileName = 'delivery-order'
      }

      if (type === 'cover') {
        fileName = 'cover-note'
      }

      if (type === 'grn') {
        fileName = 'good-return-note'
      }

      const response = await this.$http.get(
        `/delivery-confirmed/${
          this.order.logpoint_id
            ? this.order.logpoint_id
            : this.order.payment_session_id
        }/${this.order.payment_method}/print/${type}`,
        { responseType: 'blob' }
      )
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute(
        'download',
        `${fileName}-${this.order.date_added}-${this.order.id}.pdf`
      )
      document.body.appendChild(fileLink)

      fileLink.click()
    },
    async handleDownloadInvoice() {
      this.downloading = true
      const response = await axios.get(
        `/download-invoice/${
          this.order.logpoint_id
            ? this.order.logpoint_id
            : this.order.payment.id
        }/${this.order.payment_method}`,
        { responseType: 'blob' }
      )
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute(
        'download',
        `invoice-${
          this.order.payment.transaction_id ??
          this.order.payment.reference_number
        }.pdf`
      )
      document.body.appendChild(fileLink)
      fileLink.click()
      this.downloading = false
    },
    getStatusText(order) {
      let statusText
      switch (order.value) {
        case 17:
          statusText = 'For Quote'
          break
        case 12:
          statusText = 'Quoted'
          break
        case 2:
          statusText = 'Accepted'
          break
        case 3:
          statusText = 'Picked Up'
          break
        case 4:
          statusText = 'Arrived at Origin'
          break
        case 5:
          statusText = 'In Transit'
          break
        case 6:
          statusText = 'Arrived at Destination Port'
          break
        case 7:
          statusText = 'Out For Delivery'
          break
        case 8:
          statusText = 'Delivered'
          break
        case 9:
          statusText = 'Request For Cancellation'
          break
        case 10:
          statusText = 'Cancelled'
          break
        case 14:
          statusText = 'Pending Payment'
          break
        default:
          statusText = order.text
      }
      return statusText
    }
  }
}
</script>
